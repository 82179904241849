<!-- 病房智能交互系统 -->
<template>
  <div class="wrapper pageBox product-wrapper">
    <Banner title="智慧病房整体解决方案" describe="物联网智慧感知信息集成服务平台" describe2="让智慧病房更智慧" />
    <div class="product-content">
      <div class="product-name">传统痛点</div>
      <div class="pain-points">
        <div class="rectangle-item">
          <img src="@/assets/product/ward1.png" alt="">
          <div class="right-text">病区智能化程度低，很多数据需要手动录入</div>
        </div>
        <div class="rectangle-item">
          <img src="@/assets/product/ward2.png" alt="">
          <div class="right-text">系统之间缺乏联系，形成信息孤岛</div>
        </div>
        <div class="rectangle-item">
          <img src="@/assets/product/ward3.png" alt="">
          <div class="right-text">缺乏面向患者的智能化服务</div>
        </div>
        <div class="rectangle-item">
          <img src="@/assets/product/ward4.png" alt="">
          <div class="right-text">缺乏信息数据的二次分析途径及手段</div>
        </div>
        <div class="rectangle-item">
          <img src="@/assets/product/ward5.png" alt="">
          <div class="right-text">缺乏智能化病房数据的支撑</div>
        </div>
      </div>
      <div class="product-name">产品亮点</div>
      <div class="highlight">
        <div class="circle">
          <img src="@/assets/product/ward6.png" alt="">
        </div>
        <div class="bar">
          <div class="bar-item">
            <div class="color-bg">1</div>
            <div class="title">智能终端设备的应用，实现生命体征的自动采集及自动上传</div>
          </div>
          <div class="bar-item">
            <div class="color-bg">2</div>
            <div class="title">各系统互联互通，实现数据共享</div>
          </div>
          <div class="bar-item">
            <div class="color-bg">3</div>
            <div class="title">智能终端助力患者服务，满足住院日常需求</div>
          </div>
          <div class="bar-item">
            <div class="color-bg">4</div>
            <div class="title">具备运营管理、决策支持的应用，满足日常管理的需求</div>
          </div>
          <div class="bar-item">
            <div class="color-bg">5</div>
            <div class="title">分析数据，风险预警等功能，创建可视化分析图表</div>
          </div>
        </div>
      </div>
      <div class="product-name">总体规划</div>
      <div class="planning-content">
        <div class="planning">
          <div class="planning-left">
            <span>第四层</span>
          </div>
          <div class="planning-center">
            <div v-for="(item, index) in list4" :key="`list4${index}`" class="center-item">{{ item }}</div>
          </div>
          <div class="planning-right">
            <span>智能家居</span>
          </div>
        </div>
        <div class="planning">
          <div class="planning-left">
            <span>第三层</span>
          </div>
          <div class="planning-center planning-center3">
            <div v-for="(item, index) in list3" :key="`list3${index}`" class="center-item">{{ item }}</div>
          </div>
          <div class="planning-right">
            <span>智能床旁交互系统</span>
          </div>
        </div>
        <div class="planning">
          <div class="planning-left">
            <span>第二层</span>
          </div>
          <div class="planning-center planning-center2">
            <div v-for="(item, index) in list2" :key="`list2${index}`" class="center-item">{{ item }}</div>
          </div>
          <div class="planning-right planning-right1">
            <span>临床物联网应用</span>
          </div>
        </div>
        <div class="planning first-planning">
          <div class="planning-left">
            <span>第一层</span>
          </div>
          <div class="planning-center">
            <div v-for="(item, index) in list1" :key="`list1${index}`" class="center-item">{{ item }}</div>
          </div>
          <div class="planning-right planning-right1">
            <span>医护患核心应用</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Banner from '@/components/Banner';
export default {
  name: 'SmartWard',
  components: {
    Banner
  },
  data() {
    return {
      list4: ['温湿度感应', '灯光', '窗帘', '摄像头', '电视', '更多...'],
      list3: ['病房点餐', '聘请护工', '购买保险', '影音娱乐', '院内导航', '更多...', '健康教育', '费用查询', '体征监测', '医护、护嘱追踪', '满意度调查'],
      list2: ['智能床垫', '多体征采集器', '输液管理', '患者定位', '智能药柜', '更多...'],
      list1: ['健康教育', '费用查询', '满意度调查', '医嘱、护嘱追踪', '多屏联动呼吸', '手术追踪', '导管管理', '护理交班', '检查、追踪检查', '危急值管理', '陪护健康', '陪护预约', '体征监测', '陪护流量管理', '门禁管理']
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {

  }
};
</script>

<style lang='scss' scoped>
.product-wrapper {
  .product-content {
    background: #f7f8fe;
    .pain-points {
      width: 1220px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .rectangle-item {
        width: 600px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        background: #fff;
        img {
          width: 180px;
          height: 80px;
          margin-right: 40px;
        }
        .right-text {
          color: #333333;
          font-size: 16px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .highlight {
      display: flex;
      justify-content: center;
      .circle {
        width: 380px;
        height: 380px;
        background: #ffffff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        img {
          height: 320px;
          width: 320px;
        }
      }
      .bar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .bar-item {
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          &:last-child {
            margin-bottom: 0;
          }
          .color-bg {
            width: 184px;
            height: 56px;
            background: linear-gradient(270deg,#3093fa, #ffffff);
            border-radius: 0px 100px 100px 0;
            box-shadow: 4px 0px 4px 0px rgba(93,170,251,0.29);
            color: #fff;
            font-size: 32px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 20px;
            font-weight: 500;
            margin-right: 40px;
            margin-left: -138px;
          }
          &:nth-child(2n) {
            .color-bg {
              margin-left: -92px;
            }
          }
          &:nth-child(3) {
            .color-bg {
              margin-left: -46px;
            }
          }
        }
      }
    }
    .planning-content {
      width: 1046px;
      margin: 0 auto;
      padding-bottom: 142px;
      .planning {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        .planning-left {
          width: 40px;
          padding: 0 9px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #2e8afb;
          font-size: 16px;
          line-height: 22px;
          color: #fff;
          span {
            text-align: center;
          }
        }
        .planning-center {
          width: 898px;
          border: 1px dashed #979797;
          padding: 20px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 20px;
          .center-item {
            height: 48px;
            line-height: 48px;
            background: #d0e7ff;
            padding: 0 34px;
            color: #333333;
            font-size: 16px;
          }
        }
        .planning-center2 {
          .center-item {
            padding: 0 28px;
          }
        }
        .planning-center3 {
          .center-item {
            padding: 0 32px;
          }
        }
        .planning-right {
          width: 88px;
          padding: 0 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #2e8afb;
          font-size: 16px;
          line-height: 22px;
          color: #fff;
          span {
            text-align: center;
          }
        }
        .planning-right1 {
          padding: 0 12px;
        }
      }
      .first-planning {
        .planning-center {
          .center-item {
            min-width: 146px;
            height: 48px;
            line-height: 48px;
            background: #d0e7ff;
            padding: 0 20px;
            color: #333333;
            font-size: 16px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
